<template>
  <div
    class="course-card my-4"
    @click="goToCourseDetailsRoute">
    <v-row
      class="relative px-2"
      style="height: 100%"
      no-gutters
      justify="center"
      align="center">
      <v-col
        cols="3"
        class="pa-0">
        <div>
          <v-img
            class="course-card-cover"
            :src="thumbnailUrl" />
        </div>
      </v-col>
      <v-col
        cols="9"
        class="pl-4">
        <div class="course-card-title my-3 mb-4">{{ course.title }}</div>
        <v-row
          class="col-12 course-card-details py-0"
          :class="{ 'mb-1': !course.progress }"
          justify="start"
          align="center">
          <div class="d-inline-block mr-1">{{ course.category?.name }}</div>
          <div>
            <v-icon
              x-small
              class="translate-up"
              >mdi-circle-medium</v-icon
            >
          </div>
          <div class="image-container">
            <v-img
              :src="
                course.audioUrl ? require('@/assets/course/audio.svg') : require('@/assets/course/video.svg')
              "></v-img>
          </div>
          {{ course.duration }} min
        </v-row>
        <v-row
          class="col-12 pl-0 py-0 mt-5 mb-3"
          justify="space-between"
          align="center"
          no-gutters
          v-if="course.progress">
          <div style="background-color: rgba(51, 51, 51, 0.08); width: 80%; height: 3px; border-radius: 9999px">
            <v-progress-linear
              color="primaryColor"
              background-color="#33333314"
              height="3px"
              :value="course.progress"></v-progress-linear>
          </div>
        </v-row>

        <v-btn
          class="toggle-favourite-button absolute"
          small
          icon
          :loading="isToggleFavoriteLoading"
          :disabled="isToggleFavoriteLoading"
          @click.stop="toggleFavouriteStatus">
          <v-icon
            v-if="course.isFavorite"
            color="primaryColor"
            style="font-size: 16px">
            mdi-heart
          </v-icon>
          <v-icon
            v-else
            color="brandGrey2"
            style="font-size: 16px">
            mdi-heart-outline
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CourseRowCardMixin from '@/components/courses/course-row-card/CourseRowCard.mixin.vue';
  export default {
    mixins: [CourseRowCardMixin],
  };
</script>

<style lang="css" scoped>
  .course-card {
    margin: auto;
    height: fit-content;
    width: 84vw;
    background: var(--v-offWhite-base);
    border-radius: 24px;
    padding: 6px 0px;
  }
  .course-card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .course-card-details {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: var(--v-brandGrey2-base);
  }
  .course-card-cover {
    height: 80px;
    width: 80px;
    border-radius: 24px;
  }
  .translate-up {
    transform: translateY(-2px);
  }
  .toggle-favourite-button {
    right: 9px;
    bottom: 4px;
  }
  .image-container {
    width: 24px;
    height: 24px;
    transform: translateY(-1px) scale(0.4);
  }
  .image-container > img {
    width: 100%;
    height: 100%;
  }
  ::v-deep .v-progress-linear__determinate {
    height: 3px;
    border-radius: 9999px;
  }
</style>
